export const tableColumns = [
    { prop: 'group_id', label: '组合ID', width: 240, align: 'left' },
    { prop: 'group_name_cn', label: '组合名称', width: 240, align: 'left' },
    { prop: 'status', label: '组合状态', width: 240, align: 'left' },
    { prop: 'operation', label: '操作', align: 'left', fixed: 'right' }
];

export const filter = {
    group_id: '',
    group_name: '',
    status: 0
};

export const assetsStatus = [
    {
        value: 0,
        label: '全部'
    },
    {
        value: 1,
        label: '停用中'
    },
    {
        value: 2,
        label: '启用中'
    }
];
