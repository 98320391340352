<template>
    <div v-if="hasResource('asset_group_management')" class="aops-property">
      <div class="aop-searchForm search-form">
         <el-button
          v-if="hasResource('asset_group_edit')"
          class="black-btn"
          @click="add"
          >新增</el-button
        >
        <div class="aop-fix">
          <el-form :inline="true" :model="filter">
            <el-form-item label="组合ID">
              <el-input
                v-model="filter.group_id"
                placeholder="输入组合ID"
                clearable
                @keyup.enter.native="addByEnterKey"
              />
            </el-form-item>
            <el-form-item label="组合名称">
              <el-input
                v-model="filter.group_name"
                placeholder="输入组合名称"
                clearable
                @keyup.enter.native="addByEnterKey"
              />
            </el-form-item>
            <el-form-item label="组合状态">
              <el-select v-model="filter.status">
                <el-option v-for="item in assetsStatus" :key="item.label" :label="item.label" :value="item.value"/>
              </el-select>
            </el-form-item>
          </el-form>
          <div class="aop-textBtn">
            <span class="aop-clickButton" @click="inquire">查询</span>
            <span class="aop-clearButton aop-clickButton" @click="reset">清空</span>
          </div>
        </div>
      </div>
      <el-table
        v-loading="isTableLoading"
        :data="assetsData"
        class="data-table"
        header-cell-class-name="table-head"
        cell-class-name="table-cell">
        <el-table-column
            v-for="col in tableColumns"
            :key="col.label"
            :prop="col.prop"
            :label="col.label"
            :width="col.width"
            :min-width="col.minWidth"
            :align="col.align"
            :fixed="col.fixed">
            <template slot-scope="{row}">
                <div v-if="col.prop === 'operation'" class="icon-btn">
                    <el-button
                       v-if="hasResource('asset_group_edit')"
                        size="mini"
                        type="text"
                        @click="edit(row)" >
                        编辑
                    </el-button>
                    <el-button
                        v-if="hasResource(`${row.status }` === '2' ?'asset_group_close':'asset_group_open')"
                        size="mini"
                        type="text"
                        @click="setStatus(row)" >
                        {{`${row.status }` === '2'? '停用': '启用'}}
                    </el-button>
                </div>
                <div v-else-if="col.prop === 'status'">{{`${row[col.prop] }` === '2'? '启用中':'停用中'}}</div>
                <div v-else>{{row[col.prop] || '--'}}</div>
            </template>
        </el-table-column>
      </el-table>
      <div v-if="total != 0"  class="aop-pagination">
        <el-pagination
          :current-page.sync="currentPage"
          :page-sizes="[10, 20, 50]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </div>
        <!-- 启用停用弹框 -->
      <el-dialog
        top="12%"
        :close-on-click-modal="false"
        :visible.sync="visible"
        width="458px"
        class="add-dialog dialogTip"
      >
        <div class="dialog-content">
          <div class="dialog-title aop-title">是否{{`${`${currentAsset.status}` === '2' ? '停用': '启用'}【${currentAsset.group_name_cn}】`}}</div>
        </div>
        <span slot="footer" class="dialog-footer pop-dialog">
          <el-button class="confirm-button" :loading="btnLoading"  @click="submit">确认</el-button
          >
          <el-button class="cancel-button" @click="visible = false">取 消</el-button
          >
        </span>
      </el-dialog>
    </div>
</template>
<script>
import api from '../../../api/assets';
import { assetsStatus, filter, tableColumns } from './data';

export default {
    name: 'FreeAssets',
    /**
    * 基础数据&定义数据
     @return{*}
    */
    data() {
        return {
            tableColumns,
            filter,
            assetsStatus,
            total: 0,
            pageSize: 10,
            currentPage: 1,
            isTableLoading: true,
            btnLoading: false,
            assetsData: [],
            visible: false,
            currentAsset: {}
        };
    },
    /**
    * created
    */
    created() {
        this.reset();
        this.getList();
    },
    methods: {
        /**
        * 查询
         * @param {*} e 值
        */
        addByEnterKey(e) {
            if (e.keyCode == 13) {
                this.currentPage = 1;
                this.getList();
            }
        },
        /**
         * 查询
         */
        inquire() {
            this.currentPage = 1;
            this.getList();
        },
        /**
        * 查询
         * @param {*} val 值
        */
        handleSizeChange(val) {
            this.pageSize = val;
            this.getList();
        },
        /**
        * 查询
         * @param {*} val 值
        */
        handleCurrentChange(val) {
            this.currentPage = val;
            this.getList();
        },
        /**
        * 重置
        */
        reset() {
            this.currentPage = 1;
            this.filter = {
                group_id: '',
                group_name: '',
                status: 0
            };
        },
        /**
        * 添加
        */
        add() {
            this.$router.push('/property-create');
        },
        /**
        * 编辑
        * @param {*} val 值
        */
        edit(val) {
            this.$router.push(`/property-create?id=${val.group_id}`);
        },
        /**
        * 列表数据
        @returns{*}
        */
        getList() {
            this.isTableLoading = true;
            const data = {
                limit: this.pageSize,
                page: this.currentPage,
                filter: {
                    ...this.filter
                }
            };
            try {
                api.assetProperty(data).then((res) => {
                    if (res.data.code === 10200) {
                        this.assetsData = res.data.data.items;
                        this.total = res.data.data.total;
                        this.currentPage = res.data.data.page;
                    }
                    this.isTableLoading = false;
                });
            } catch (e) {
                return e;
            }
        },
        /**
        * 修改状态
        * @param {*} row 值
        */
        setStatus(row) {
            this.visible = true;
            this.currentAsset = row;
        },
        /**
        * 提交修改状态
        @return {*}
         *
        */
        submit() {
            this.btnLoading = true;
            const row = this.currentAsset;
            try {
                api.setAssetPropertyStatus({ group_id: row.group_id, status: `${row.status}` === '2' ? 1 : 2 }).then((res) => {
                    if (res.data.code === 10200) {
                        this.visible = false;
                        this.currentAsset = {};
                        this.$$success('操作成功');
                        this.getList();
                    } else {
                        this.$$error(res.data.message);
                    }
                    this.btnLoading = false;
                });
            } catch (e) {
                return e;
            }
        }
    }
};
</script>

<style lang="less">
    @import "./index.less";
</style>
